import React, { useState, useLayoutEffect, useRef }  from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useCookies } from 'react-cookie';
import FrontServiciosAPI from '../servicios/FrontServicios';
import { Config } from '../comun';
import md5 from 'crypto-js/md5'; 
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import Constantes from '../comun/Constantes';
import CuerpoCarga from '../comun/CuerpoCarga';

function Inicio (){
    const [pagina, setPagina] = useState(null);
    const [logueando, setLogueando] = useState(false);
    const [, setCookie] = useCookies(['user']);
    const [cargando, setCargando] = useState(false);
    const toast = useRef(null);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    async function cargarPaginaInicio() {
        setCargando(true);
        const response = await FrontServiciosAPI.getFront('paginainicio');
        if(response.ok){
            const html = await response.json();
            

            //let strpagina = html.pagina;
            let strpagina = html.pagina.replaceAll(/(<link href=")(?!http)/g,'<link href=\"'+Config.cloudFront+html.materia.id+'/paginainicio/');
            strpagina = strpagina.replaceAll(/(rel="stylesheet" href=")(?!http)/g,'rel="stylesheet" href=\"'+Config.cloudFront+html.materia.id+'/paginainicio/');
            strpagina = strpagina.replaceAll(/(src=")(?!http)/g,'src=\"'+Config.cloudFront+html.materia.id+'/paginainicio/');
            //console.log(strpagina);
            var parser = new DOMParser();
            var doc = parser.parseFromString(strpagina, 'text/html');
            //console.log('head',doc)
            /*var tags = doc.querySelectorAll("script");
            tags.forEach(t=>{
                t.setAttribute('async','async')
                document.body.appendChild(t);
            })*/
            /*var tags = doc.querySelectorAll("link");
            tags.forEach(t=>{
                reacomodaLinks(t);
            });*/

            

            for (let i = 0; i < doc.head.childNodes.length; i++) {
                if(doc.head.childNodes[i].tagName==='LINK' || doc.head.childNodes[i].tagName==='SCRIPT' || doc.head.childNodes[i].tagName==='STYLE'){
                    document.head.appendChild(doc.head.childNodes[i]);
                }
            }
            /*if(doc.head.childNodes.length===0){
                for (let i = 0; i < doc.body.childNodes.length; i++) {
                    //console.log('body',doc.body.childNodes[i].attribs);
                    if(doc.body.childNodes[i].tagName==='LINK' || doc.body.childNodes[i].tagName==='SCRIPT' || doc.body.childNodes[i].tagName==='STYLE'){
                        document.head.appendChild(doc.body.childNodes[i]);
                    }
                }
            }*/
            
            document.title=html.materia.nombre;
            document.getElementById('favicon').href=`${Config.cloudFront}${html.materia.id}/recursos/favicon.png`;

            console.log('redirectinicio qs2',doc.body.querySelector('#redireccionarinicio'))
            if(doc.body.querySelector('#redireccionarinicio')!=null){
                console.log('si hay redireccion')
                window.location.href=doc.body.querySelector('#redireccionarinicio').innerHTML
            }
            
            if(doc.body.getAttribute('class')!==''){
                document.body.setAttribute('class',doc.body.getAttribute('class'));
            }
            //document.body.innerHTML=doc.body.innerHTML;
            setPagina(doc.body.innerHTML);
            //setPagina(strpagina);
            localStorage.setItem(Constantes.LSDATOSMATERIA+html.materia.id, JSON.stringify(html.materia.datos));
            delete html.materia['datos'];
            setCookie(Constantes.NOMBRECKMATERIA, html.materia, { path: '/' });
        }else{
            const error = await response.json();
            console.log('error',error);
        }
        setCargando(false);
    };

    const validaLoginApi = async () => {
        const response = await FrontServiciosAPI.postFront('entrar',{apikey: urlParams.get('api')});
        if(!response.ok){
            const errormsj = await response.json();
            console.log('error login api ',errormsj)
        }else{
            let datosUsuario = await response.json();
            //console.log('usuario',datosUsuario);
            //datosUsuario.usuario.datos = JSON.parse(datosUsuario.usuario.datos);
            setCookie(Constantes.NOMBRECKUSUARIO, datosUsuario.estudiante, { path: '/' });
            setCookie(Constantes.NOMBRECKCOOKIE, datosUsuario.cookie, { path: '/' });
            setCookie(Constantes.NOMBRECKOTRASMATERIAS, datosUsuario.materias, { path: '/' });

            localStorage.setItem(Constantes.LSDATOSMATERIA+datosUsuario.materia.id, JSON.stringify(datosUsuario.materia.datos));
            delete datosUsuario.materia['datos'];
            setCookie(Constantes.NOMBRECKMATERIA, datosUsuario.materia, { path: '/' });
            
            window.location.reload(false);
        }
      }

    useLayoutEffect( () => {
        //console.log('apikey',urlParams.get('api'))
        if(urlParams.get('api') && urlParams.get('api')!==''){
            validaLoginApi()
        }else{
            cargarPaginaInicio();
        }
		
	}, []);

    const _entrar = async () => {
        if(document.getElementById('acuerdo') && !document.getElementById('acuerdo').checked){
            toast.current.show({severity:'warn', detail:'Por favor marque la casilla de acuerdo', sticky: true});
            return;
        }
        setLogueando(true);
        const response = await FrontServiciosAPI.postFront('entrar',{usuario: document.getElementById('usuario').value, clave: md5(document.getElementById('clave').value).toString()});
        if(!response.ok){
            const errormsj = await response.json();
            toast.current.show({severity:'warn', detail:errormsj, sticky: true});
        }else{
            let datosUsuario = await response.json();
            //console.log('usuario',datosUsuario);
            //datosUsuario.usuario.datos = JSON.parse(datosUsuario.usuario.datos);
            setCookie(Constantes.NOMBRECKUSUARIO, datosUsuario.estudiante, { path: '/' });
            setCookie(Constantes.NOMBRECKCOOKIE, datosUsuario.cookie, { path: '/' });
            setCookie(Constantes.NOMBRECKOTRASMATERIAS, datosUsuario.materias, { path: '/' });
            
            window.location.reload(false);
        }
        setLogueando(false);
    }

    const CargarPagina = () => {
        return ReactHtmlParser(pagina, {
            transform: (node) => {
                if (node.type === 'tag' && node.name === 'button' && node.attribs.type==="submit") {
                    return <Button key="bt1" type="button" loading={logueando} className={node.attribs.class} label={node.children[0].data} onClick={() =>_entrar()} />;
                }else if (node.type === 'script') {
                    return null;
                }
            },
        });
    }

    return <div><Toast ref={toast} /><CuerpoCarga cargando={cargando}>{pagina?<CargarPagina key="1" />:null}</CuerpoCarga></div>;
}

export default Inicio;