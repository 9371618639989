import React, { Suspense, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Constantes, Config } from './comun';
import {
  BrowserRouter as Router, Routes, Route
} from "react-router-dom";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import Inicio from './publico/Inicio';
import FrontServiciosAPI from './servicios/FrontServicios';

const Layout = React.lazy(() => import('./privado/Layout'));
const Registro = React.lazy(() => import('./publico/Registro'));
const Iframe = React.lazy(() => import('./comun/Iframe'));

function App() {
  const [cookies] = useCookies(['user']);

  document.title=cookies.CRUSUARIOWEB?cookies[Constantes.NOMBRECKMATERIA].nombre:'';
  if(cookies.CRUSUARIOWEB){
    document.getElementById('favicon').href=`${Config.cloudFront}${cookies[Constantes.NOMBRECKMATERIA].id}/recursos/favicon.png`;
  }
  return (
    <Router>
        {cookies.CRUSUARIOWEB ? <Suspense fallback={<div>Cargando...</div>}>
        <Layout />
      </Suspense>:
      <Routes>
          <Route path="/registroexitoso" element={cookies.CRMATERIAWEB && <Suspense fallback={<div>Cargando...</div>}>
              <Iframe url={Config.cloudFront+cookies[Constantes.NOMBRECKMATERIA].id+'/paginaregistro/exito.html'} />
            </Suspense>} />
          <Route path="/registro" element={<Suspense fallback={<div>Cargando...</div>}>
              <Registro />
            </Suspense>} />
          <Route path="/" element={<Inicio />} />
        </Routes>}
    </Router>
  );
}

export default App;
